<script lang="ts" setup>
import { ref, watch, getCurrentInstance } from "vue";
import { getEnergyLabelStatus } from "@/services/api/chart.api";
import _ from "lodash";

const props = defineProps({
  token: {
    type: String,
  },
  partyType: {
    type: String,
  },
  labelSource: {
    type: String,
  },
});

const labelData = ref({});
const tableData = ref({
  zeroToThreeFour: 0,
  fourToSevenYears: 0,
  sevenYears: 0,
  eightYears: 0,
  nineYears: 0,
  expired: 0,
  noLabel: 0,
  totalNumberOfBuildings: 0,
});
const isLoading = ref(true);

watch(
  () => props.token,
  () => {
    if (props.token) {
      fetchBuildingEnergyLabelStatusData();
    } else {
      tableData.value = {};
    }
  },
  { immediate: true }
);

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

async function fetchBuildingEnergyLabelStatusData() {
  isLoading.value = true;
  const response = await getEnergyLabelStatus(props.token, props.partyType, props.labelSource);

  if (response) {
    tableData.value = response.data;
    // N.B.: Dit gebruik van segmentParser() zorgt ervoor dat de volgorde waarin de labels
    // door de back-end waren gezet verloren gaat. Als het wenselijk is om de data te
    // presenteren in de volgorde waarin ze door de back-end worden aangeleverd, dan
    // is het zaak om over response.data.labels als array te itereren bij het aanmaken van
    // de keys en values arrays, en niet als object.
    // Aangezien er wel wat voor te zeggen is om het sorteren in de front-end te doen,
    // is de huidige aanpak niet echt een probleem, dus laat ik het maar zo, maar het kan
    // dus wel eleganter.
    labelData.value = segmentParser(response.data.labels, "labelCategory");
    isLoading.value = false;
  } else {
    isLoading.value = false;
    proxy.$toaster.error("Ophalen van de buildingEnergyLabelStatus data is mislukt!");
  }
}

function segmentParser(segments, key = "text", value = "value") {
  let parsedData = {};
  let chartSegments = _.sortBy(segments, key);

  _.each(chartSegments, (chartSegment) => {
    parsedData[chartSegment[key]] = chartSegment[value];
  });

  return parsedData;
}
</script>

<template>
  <v-layout class="widget-item">
    <header>
      <h2>Status van labels</h2>
      <div class="widget-actions">
        <information-dropdown id="status-labels">
          <template v-slot:header>
            <h2>Status van labels</h2>
          </template>
          <p>
            Hier worden de labels en leeftijd getoond van de energielabels van al uw {{ props.partyType == "Homes" ? "complexen" : "gebouwen" }}. Een energielabel laat de energieprestatie van een
            {{ props.partyType == "Homes" ? "complex" : "gebouw" }} zien. Ook maakt het energielabel duidelijk welke energiebesparende maatregelen mogelijk zijn. De labelklasse voor
            {{ props.partyType == "Homes" ? "complexen" : "utiliteitsbouw" }} loopt van A++++ t/m G, van weinig naar veel besparingsmogelijkheden.
          </p>
        </information-dropdown>
      </div>
    </header>
    <loader-spinner v-show="isLoading === true" :is-loading="isLoading" :min-height="200"></loader-spinner>
    <div class="widget-wrapper" v-show="isLoading === false">
      <div class="widget-chart">
        <chart-building-energy-labels :width="200" :height="200" legendPosition="legend-bottom" :source-data="labelData" :partyType="props.partyType"></chart-building-energy-labels>
      </div>
      <div class="widget-info">
        <h3>Leeftijd labels</h3>
        <table-expiration-date-age :table-data="tableData"></table-expiration-date-age>
      </div>
    </div>
  </v-layout>
</template>
