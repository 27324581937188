var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "perfect-scrollbar",
    [
      _c("h2", [_vm._v("Netto warmtebehoefte")]),
      _c(
        "information-dropdown",
        {
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_c("h2", [_vm._v("Netto warmtebehoefte")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("p", [
            _vm._v(
              "Dit toont het percentage van de woningen waarvan de netto warmtebehoefte voldoet aan de standaard."
            ),
          ]),
          _c("p", [
            _vm._v(" De "),
            _c("b", [_vm._v("Netto warmtevraag/behoefte")]),
            _vm._v(
              " is een getal dat vertelt hoeveel energie er nodig is voor het verwarmen van de woning en wordt uitgedrukt in kilowattuur per vierkante meter per jaar (kWh/m²/jaar). "
            ),
          ]),
          _c("p", [
            _vm._v(" De "),
            _c("b", [_vm._v("Standaard")]),
            _vm._v(
              " richt zich op de definitie van een zogenaamde “spijtvrije” verbouwing waarmee de gebouweigenaar ervan verzekerd is dat later, bij aansluiting op duurzame bronnen met een lagere temperatuur, niet nogmaals een ingrijpende aanpassing aan dezelfde bouwdelen noodzakelijk is. "
            ),
          ]),
        ]
      ),
      _c("div", { staticClass: "paris-score" }, [
        _setup.isLoaded
          ? _c("span", { class: _setup.nhSummary.colorClass }, [
              _vm._v(
                " Percentage van het aantal woningen dat voldoet aan de warmtebehoefte: " +
                  _vm._s(_setup.nhSummary.percentage) +
                  "% "
              ),
            ])
          : _vm._e(),
      ]),
      _setup.isLoaded
        ? _c("h5", [
            _vm._v(
              "Gebaseerd op " +
                _vm._s(_setup.nhSummary.counted) +
                " van de " +
                _vm._s(_setup.nhSummary.total) +
                " woningen"
            ),
          ])
        : _vm._e(),
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { md: "12", sm: "6" } }, [
                _c(
                  "div",
                  { staticClass: "paris-proof-score" },
                  [
                    _c("house-widget", {
                      attrs: { colorClass: _setup.nhSummary.colorClass },
                    }),
                  ],
                  1
                ),
              ]),
              _c("v-col", { attrs: { md: "12", sm: "6" } }, [
                _c("div", { staticClass: "paris-proof-legend" }, [
                  _c("div", { staticClass: "item" }, [
                    _c("span", { staticClass: "color color-red" }),
                    _vm._v(" "),
                    _c("b", [_vm._v("0 - 49%")]),
                  ]),
                  _c("div", { staticClass: "item" }, [
                    _c("span", { staticClass: "color color-orange" }),
                    _vm._v(" "),
                    _c("b", [_vm._v("50 - 84%")]),
                  ]),
                  _c("div", { staticClass: "item" }, [
                    _c("span", { staticClass: "color color-green" }),
                    _vm._v(" "),
                    _c("b", [_vm._v("85 - 100%")]),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }