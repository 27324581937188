import { apiClient, getRequest, postRequest, severityCode } from "./httpClient";
import _ from "lodash";

const END_POINT = "chart";

const getParisProofScore = async (token) => {
  return await apiClient.get(`${END_POINT}/${token}/parisproofscore`);
};

const getDataAvailability = async (token) => {
  return await apiClient.get(`${END_POINT}/${token}/dataAvailability`);
};

const getBuildingEnergyLabels = async (token, partyType, labelSource) => {
  const response = await apiClient.get(`${END_POINT}/${token}/energyLabels`, { params: { partyType: partyType, labelSource: labelSource } });
  if (response) {
    let labels = {};
    let chartSegments = _.sortBy(response.data.segments, "text");

    _.each(chartSegments, (chartSegment) => {
      labels[chartSegment.text] = chartSegment.value;
    });
    return labels;
  } else {
    this.$toaster.error("Ophalen van buildingEnergyLabels is mislukt!");
  }
};

const getBuildingEnergyLabelsParty = async (partyReference, filterData, partyType, labelSource) => {
  const response = await postRequest(`${END_POINT}/party/${partyReference}/energyLabels`, { filterData: filterData }, { partyType: partyType, labelSource: labelSource });
  console.log("chart.api getBuildingEnergyLabelsParty ", response);
  if (response) {
    let labels = {};
    let chartSegments = _.sortBy(response.data.segments, "text");

    _.each(chartSegments, (chartSegment) => {
      labels[chartSegment.text] = chartSegment.value;
    });
    return labels;
  } else {
    this.$toaster.error("Ophalen van buildingEnergyLabels is mislukt!");
  }
};

const getBuildingEcmFunctions = async (token) => {
  const response = await getRequest(`${END_POINT}/${token}/buildingEcmFunctions`);
  if (response.severity <= severityCode.warning) {
    let functions = {};

    _.each(response.data.functions.segments, (chartSegment) => {
      functions[chartSegment.text ? chartSegment.text : "Onbekend"] = {
        val: chartSegment.value,
        color: chartSegment.color,
      };
    });
    return { data: functions, message: undefined, severity: response.severity };
  } else {
    return { data: undefined, message: response.message, severity: response.severity };
  }
};

const getBuildingSquareMeters = async (token) => {
  const response = await getRequest(`${END_POINT}/${token}/squareMeters`);
  //console.log("chart.api getBuildingSquareMeters response ", response);
  if (response.severity <= severityCode.warning) {
    let squareMeters = {
      total: 0,
      data: {},
    };

    _.each(response.data.squareMeters.segments, (chartSegment) => {
      squareMeters.data[chartSegment.text] = chartSegment.value;
    });

    squareMeters.total = response.data.squareMetersTotal;

    return { data: squareMeters, message: undefined, severity: response.severity };
  } else {
    return { data: undefined, message: response.message, severity: response.severity };
  }
};

const getEnergyLabelStatus = async (token, partyType, labelSource) => {
  return await apiClient.get(`${END_POINT}/${token}/energyLabelStatus`, { params: { partyType: partyType, labelSource: labelSource } });
};

const getBuildingEnergyLabelSummary = async (partyReference, filterData, labelSource) => {
  return await postRequest(`${END_POINT}/party/${partyReference}/buildingEnergyLabelSummary`, { filterData: filterData }, { labelSource: labelSource });
};

const getBuildingMeasures = async (token, certified, refDate) => {
  return await apiClient.get(`${END_POINT}/${token}/measures/${refDate}?certified=${certified}`);
};

const getHomesGaslessDashboard = async (token) => {
  return await apiClient.get(`${END_POINT}/${token}/homesGaslessDashboard`);
};

const getCO2Emissions = async (token /*, refDate*/) => {
  let response = await apiClient.get(`${END_POINT}/${token}/homesCO2Dashboard`);
  return {
    data: {
      any: true,
      std2050: response.data.standard2050,
      present: response.data.current,
      totalHouses: response.data.totalHouses,
      countedHouses: response.data.countedHouses,
      totalApartments: response.data.totalApartments,
      countedApartments: response.data.countedApartments,
    },
  };
};

const getCO2Percentage = async (token /*, refDate*/) => {
  let response = await apiClient.get(`${END_POINT}/${token}/homesCO2Percentage`);
  return {
    data: {
      any: true,
      conformingHouses: response.data.conformingHouses,
      conformingApartments: response.data.conformingApartments,
      totalHouses: response.data.totalHouses,
      totalApartments: response.data.totalApartments,
    },
  };
};

const getHomeTypes = async (token /*, refDate */) => {
  let response = await apiClient.get(`${END_POINT}/${token}/homeTypesDashboard`);
  return {
    data: {
      houses: response.data.houses,
      apartments: response.data.apartments,
    },
  };
};

const getHomesMeetingNetHeatRequirement = async (token /*, refDate*/) => {
  let response = await apiClient.get(`${END_POINT}/${token}/homesMeetingNetHeatRequirementDashboard`);
  return {
    data: {
      any: true,
      total: response.data.homesTotal,
      counted: response.data.homesCounted,
      meetingNetHeatRequirement: response.data.homesMeetingNetHeatRequirement,
    },
  };
};

const getAppliedOverviewTypes = async (token) => {
  const response = await apiClient.get(`${END_POINT}/applied/${token}/types`);
  if (response) {
    let types = {};

    let chartSegments = _.sortBy(response.data.segments, "text");

    _.each(chartSegments, (chartSegment) => {
      types[chartSegment.text] = chartSegment.value;
    });
    return types;
  } else {
    this.$toaster.error("Ophalen van maatregeltypen is mislukt!");
  }
};

const getAppliedOverviewStatusses = async (token) => {
  const response = await apiClient.get(`${END_POINT}/applied/${token}/statusses`);
  if (response) {
    let statusses = {};

    let chartSegments = _.sortBy(response.data.segments, "key");

    _.each(chartSegments, (chartSegment) => {
      statusses[chartSegment.key] = chartSegment.value;
    });
    return statusses;
  } else {
    this.$toaster.error("Ophalen van maatregelstatussen is mislukt!");
  }
};

const getAppliedOverviewReturnOnInvestment = async (token) => {
  const response = await apiClient.get(`${END_POINT}/applied/${token}/returnOnInvestment`);
  if (response) {
    let types = {};

    let chartSegments = response.data.segments;

    _.each(chartSegments, (chartSegment) => {
      types[chartSegment.text] = chartSegment.value;
    });
    return types;
  } else {
    this.$toaster.error("Ophalen van maatregel terugverdientijden is mislukt!");
  }
};

const getInvestmentVsPPS = async (token) => {
  return await apiClient.get(`${END_POINT}/applied/${token}/investmentVsPPS`);
};

const GetCO2EmissionVsSavingUtility = async (token) => {
  return await apiClient.get(`${END_POINT}/applied/${token}/co2EmissionVsSavingUtility`);
};

const GetInvestmentAndCO2Home = async (token) => {
  return await apiClient.get(`${END_POINT}/applied/${token}/investmentAndCo2Home`);
};

const GetInvestmentVsHomesMeetingNetHeatRequirement = async (token) => {
  return await apiClient.get(`${END_POINT}/applied/${token}/investmentVsHomesMeetingNetHeatRequirement`);
};

const GetSustainabilityRadarData = async (partyReference) => {
  return await apiClient.get(`${END_POINT}/applied/${partyReference}/sustainabilityRadarData`);
};

const GetEnergyCompassData = async (token) => {
  return await apiClient.get(`${END_POINT}/energyCompassData/${token}`);
};

export {
  getParisProofScore,
  getDataAvailability,
  getBuildingEnergyLabels,
  getBuildingEnergyLabelsParty,
  getBuildingEcmFunctions,
  getBuildingSquareMeters,
  getEnergyLabelStatus,
  getBuildingEnergyLabelSummary,
  getBuildingMeasures,
  getHomesGaslessDashboard,
  getCO2Emissions,
  getCO2Percentage,
  getHomeTypes,
  getHomesMeetingNetHeatRequirement,
  getAppliedOverviewTypes,
  getAppliedOverviewStatusses,
  getAppliedOverviewReturnOnInvestment,
  getInvestmentVsPPS,
  GetCO2EmissionVsSavingUtility,
  GetInvestmentAndCO2Home,
  GetInvestmentVsHomesMeetingNetHeatRequirement,
  GetSustainabilityRadarData,
  GetEnergyCompassData,
  severityCode,
};
