<script lang="ts" setup>
import { watch, ref, getCurrentInstance } from "vue";
import { getHomesMeetingNetHeatRequirement } from "@/services/api/chart.api";

const props = defineProps({
  token: String,
  refDate: Date,
});

const nhSummary = ref({
  counted: 0,
  colorClass: "color-grey",
  percentage: 0,
  total: 0,
});
const isLoading = ref(false);
const isLoaded = ref(false);

watch(
  () => props.token,
  () => {
    fetchData();
  },
  { immediate: true }
);

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

async function fetchData() {
  //console.log("NetHeatRequirement fetchData ", this.token);
  isLoaded.value = false;
  if (props.token) {
    isLoading.value = true;
    const response = await getHomesMeetingNetHeatRequirement(props.token);
    if (response) {
      nhSummary.value.counted = response.data.counted;
      nhSummary.value.total = response.data.total;
      const pct = Math.trunc((100 * response.data.meetingNetHeatRequirement) / response.data.total + 0.5);

      if (Number.isNaN(pct)) {
        nhSummary.value.colorClass = "color-grey";
      } else if (pct < 50) {
        nhSummary.value.colorClass = "color-red";
      } else if (pct < 85) {
        nhSummary.value.colorClass = "color-orange";
      } else {
        nhSummary.value.colorClass = "color-green";
      }
      nhSummary.value.percentage = pct;
      isLoaded.value = true;
    } else {
      console.error("Failed to get Net Heat Requirement summary");
      proxy.$toaster.error("Ophalen van de Netto Warmtebehoefte is mislukt!");
    }
    isLoading.value = false;
  }
}
</script>

<template>
  <perfect-scrollbar>
    <h2>Netto warmtebehoefte</h2>

    <information-dropdown>
      <template v-slot:header>
        <h2>Netto warmtebehoefte</h2>
      </template>

      <p>Dit toont het percentage van de woningen waarvan de netto warmtebehoefte voldoet aan de standaard.</p>

      <p>
        De <b>Netto warmtevraag/behoefte</b> is een getal dat vertelt hoeveel energie er nodig is voor het verwarmen van de woning en wordt uitgedrukt in kilowattuur per vierkante meter per jaar
        (kWh/m²/jaar).
      </p>

      <p>
        De <b>Standaard</b> richt zich op de definitie van een zogenaamde “spijtvrije” verbouwing waarmee de gebouweigenaar ervan verzekerd is dat later, bij aansluiting op duurzame bronnen met een
        lagere temperatuur, niet nogmaals een ingrijpende aanpassing aan dezelfde bouwdelen noodzakelijk is.
      </p>
    </information-dropdown>

    <div class="paris-score">
      <span v-if="isLoaded" :class="nhSummary.colorClass"> Percentage van het aantal woningen dat voldoet aan de warmtebehoefte: {{ nhSummary.percentage }}% </span>
    </div>

    <h5 v-if="isLoaded">Gebaseerd op {{ nhSummary.counted }} van de {{ nhSummary.total }} woningen</h5>

    <v-container>
      <v-row>
        <v-col md="12" sm="6">
          <div class="paris-proof-score">
            <house-widget :colorClass="nhSummary.colorClass"></house-widget>
          </div>
        </v-col>
        <v-col md="12" sm="6">
          <div class="paris-proof-legend">
            <div class="item"><span class="color color-red"></span> <b>0 - 49%</b></div>
            <div class="item"><span class="color color-orange"></span> <b>50 - 84%</b></div>
            <div class="item"><span class="color color-green"></span> <b>85 - 100%</b></div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </perfect-scrollbar>
</template>
